import { nanoid } from 'nanoid';
import Axios from 'axios';
import {
  isEmail,
  emailAnswerFinder,
  generateSessionLink
} from '@jotforminc/utils';
import { StorageHelper } from '@jotforminc/storage-helper';

export default class SCLManager {
  constructor(options = {}) {
    this.inited = false;
    const {
      apiURL, formID, onStateChange
    } = options;
    this.onStateChange = onStateChange;
    this.states = Object.freeze({
      default: 0, saving: 1, saved: 2, error: 3
    });
    this.formID = formID;
    this.apiURL = apiURL;
    this.localSessionKey = `${SCLManager.SACL_DETAILS_KEY}_${this.formID}`;

    this.additionalStaticData = [{ name: 'continueLater', value: '1' }];
  }

  setAdditionalStaticData = (data = []) => {
    this.additionalStaticData = data;
  };

  static SACL_DETAILS_KEY = 'JFU_SCL_details';

  changeState = newState => {
    if (this.onStateChange) {
      this.onStateChange(newState);
    }
  };

  initiate = (onSuccess, onError, loading) => {
    console.log('manager.initiate');
    if (!this.inited) {
      this.inited = true;
      if (!window.JotForm.sessionID) {
        window.JotForm.sessionID = this.generateSessionID();
      }

      if (!window.JotForm.submissionToken) {
        window.JotForm.submissionToken = this.generateToken();
      }

      this.sessionID = window.JotForm.sessionID;
      this.token = window.JotForm.submissionToken;
    }
    this.hiddenSubmit(false, onSuccess, onError, loading);
    this.generateLink();
  };

  generateLink = () => {
    // consider custom urls, use pathname instead of form id
    // remove leading and trailing slashes in pathname
    this.continueLink = generateSessionLink(this.sessionID, this.token, this.formID);

    const currentURL = new URL(window.location.href);
    const params = Array.from(currentURL.searchParams.entries()).map(([key, value]) => ({ key, value }));
    const excludedParams = ['stoken', 'session', 'jumpToPage'];

    const continueURL = new URL(this.continueLink);
    const extraParams = {};
    params.forEach(param => {
      if (!excludedParams.includes(param.key)) {
        extraParams[param.key] = param.value;
        continueURL.searchParams.append(param.key, param.value);
      }
    });

    // For legacy forms add jumpTo
    if (!window.CardForm) {
      const sections = document.querySelectorAll('.form-section:not([id^=section_])');
      if (sections.length > 1) {
        const index = [...sections].indexOf(window.JotForm.currentSection);
        continueURL.searchParams.append('jumpToPage', index >= 0 ? (index + 1) : 1);
      }
    }

    if (this.localSessionKey) {
      StorageHelper.setLocalStorageItem({
        key: this.localSessionKey,
        value: {
          token: this.token,
          created_at: new Date(),
          extraParams: extraParams,
          session_id: this.sessionID
        }
      });
    }

    this.continueLink = continueURL.href;
    return {
      link: this.continueLink,
      extraParams
    };
  };

  generateSessionID() {
    const uniq = nanoid(8);
    return `JF-S4L-${uniq}`;
  }

  generateToken = () => {
    const uniq = nanoid(8);
    const formIdLastNumber = this.formID.toString().slice(-1) / 1;
    const checkSum = (formIdLastNumber * 4) + 7;
    return `JF-${uniq}-${checkSum}`;
  };

  getInputNamesAndValues(jotformForm) {
    const formData = Array.from(new FormData(jotformForm).entries()) // eslint-disable-line no-undef
      .map(([name, value]) => ({ name, value }));

    const questionLines = document.querySelectorAll('li.form-line');
    for (let i = 0; i < questionLines.length; i++) {
      const questionLine = questionLines[i];
      const questionType = questionLine.dataset.type;

      if (['control_radio', 'control_checkbox'].indexOf(questionType) > -1) {
        const inputs = questionLine.querySelectorAll('input');
        let empty = true;
        for (let j = 0; j < inputs.length; j++) {
          const input = inputs[j];
          if (input.checked) {
            empty = false;
          }

          if (empty && input.hasAttribute('data-otherhint')) {
            for (let k = 0; k < formData.length; k++) {
              if (formData[k].name === input.name) {
                formData[k].empty = true;
                break;
              }
            }
          }
        }

        if (empty) {
          formData.push({
            name: inputs[0].name,
            value: '',
            empty: true
          });
        }
      } else if (!!window.CardForm && questionType === 'control_textarea') {
        const textAreaField = questionLine.querySelector('.mdInput');
        const textareaDiv = questionLine.querySelector('.jfTextarea-editor');
        const currData = textAreaField && formData.find(data => data.name === textAreaField.name);

        if (currData && textareaDiv) {
          currData.value = textareaDiv.innerHTML;
        }
      }
    }

    return formData;
  }

  // eslint-disable-next-line complexity, max-statements
  getFormDataDiff(currentData, previousData, jotformForm) {
    if (!previousData) {
      return currentData;
    }

    const diff = [];
    const regexOneDim = /\[.*?\]$/g; // 1D array like inputs include '[*]' in their names
    const regexOneDimWithEmptyKeyName = /\[\]$/g; // 1D array like inputs include '[]' in their names such as 'control_matrix', multiselect 'control_imagechoice' or 'control_dropdown'
    const regexTempFile = /temp_upload\[.*?]\[.*?]$/g; // file upload input name format
    const regexTwoDim = /\[.*?\]\[.*?\]$/g; // 2D array like inputs include '[*][]' in their names

    for (let i = 0; i < currentData.length; i++) {
      const current = currentData[i];
      const isOneDim = current.name.match(regexOneDim);
      const isOneDimWithEmptyKeyName = current.name.match(regexOneDimWithEmptyKeyName);
      const isTempFile = current.name.match(regexTempFile);
      const isTwoDim = current.name.match(regexTwoDim);

      if (isOneDim && !isTempFile) {
        // if it is a two dimensional array input like 'control_matrix', always return all of them although it has no value. otherwise the order of inputs will be changed
        // apply same logic above for 1D array like inputs include '[]' in their names
        if (isTwoDim || isOneDimWithEmptyKeyName) {
          diff.push(current);
          continue;
        } else if (current.name.include('[other]')) {
          const checkboxOther = jotformForm.querySelector(`input[type="checkbox"][name="${current.name}"]`);
          if ((checkboxOther && !checkboxOther.checked) || (!current.value)) {
            continue;
          }
          // send other value with other checkbox values otherwise its value won't be saved properly
          const checkboxGroupName = current.name.split('[other]')[0];
          const checkboxGroup = currentData.find(data => data.name === `${checkboxGroupName}[]`);
          if (checkboxGroup) {
            diff.push(current);
            continue;
          }
        }
      }

      let isChanged = true;
      for (let j = 0; j < previousData.length; j++) {
        if (previousData[j].name === current.name && previousData[j].value === current.value) {
          isChanged = false;
          break;
        }
      }

      if (isChanged) {
        if (isOneDim && !isTwoDim) {
          diff.push(current);
          const currentQid = current.name.split('_')[0];
          for (let j = 0; j < previousData.length; j++) {
            const data = previousData[j];
            const dataQid = data.name.split('_')[0];
            if (dataQid === currentQid && data.name !== current.name) {
              let isExist = false;
              for (let k = 0; k < diff.length; k++) {
                if (diff[k].name === data.name) {
                  isExist = true;
                  break;
                }
              }

              if (!isExist) {
                diff.push(data);
              }
            }
          }
        } else {
          diff.push(current);
        }
      }
    }

    return diff;
  }

  mergeDiffWithStaticData(diff) {
    const data = [
      ...this.additionalStaticData,
      { name: 'formID', value: this.formID },
      { name: 'session_id', value: window.JotForm.sessionID },
      { name: 'submission_token', value: window.JotForm.submissionToken }
    ];

    const isCardForm = !!window.CardForm;
    if (isCardForm) {
      const { currentCardIndex } = window.CardForm.layoutParams;
      data.push({ name: 'continue_card_index', value: currentCardIndex });
    }
    if (window.JotForm.submissionID) {
      data.push({ name: 'submission_id', value: window.JotForm.submissionID });
    }

    if (window.JotForm.tempUploadFolderInjected) {
      const tempUploadFolderInput = document.querySelector('input[name="temp_upload_folder"]');
      if (tempUploadFolderInput) {
        data.push({ name: 'temp_upload_folder', value: tempUploadFolderInput.value });
      } else {
        console.error('No temp upload folder input found');
      }
    }

    if (window.JotForm.uploadServerUrlInjected) {
      const tempUploadFolderInput = document.querySelector('input[name="uploadServerUrl"]');
      if (tempUploadFolderInput) {
        data.push({ name: 'uploadServerUrl', value: tempUploadFolderInput.value });
      } else {
        console.error('No temp upload folder input found');
      }
    }

    const simpleSpc = document.getElementById('simple_spc');
    if (simpleSpc) {
      data.push({ name: 'simple_spc', value: simpleSpc.value });
    }

    const merged = diff.slice(0);
    for (let i = 0; i < data.length; i++) {
      let isExists = false;
      for (let j = 0; j < merged.length; j++) {
        if (merged[j].name === data[i].name) {
          isExists = true;
          break;
        }
      }

      if (!isExists) {
        merged.push(data[i]);
      }
    }

    return merged;
  }

  createInput(props) {
    const keys = Object.keys(props);
    const input = document.createElement('input');
    for (let i = 0; i < keys.length; i++) {
      const prop = keys[i];
      input[prop] = props[prop];
    }
    return input;
  }

  removeSaveIndicator() {
    window.JotForm.saving = false;
    window.JotForm.enableButtons();

    const saveIndicator = document.querySelector('.form-saving-indicator');
    if (saveIndicator) {
      saveIndicator.remove();
    }
  }

  continue = ({
    email,
    sessionID,
    submissionToken,
    lastAnswers
  } = {}) => {
    this.inited = true;
    this.sessionID = sessionID || window.JotForm.sessionID;
    this.token = submissionToken || window.JotForm.submissionToken;

    if (lastAnswers) {
      this.lastAnswers = lastAnswers;
    }

    if (email && !this.getEmailSentTo()) {
      this.setHiddenEmailSentTo(email);
    }
  };

  sendEmail(targetEmailAddress) {
    const email = targetEmailAddress || emailAnswerFinder();
    if (!isEmail(email)) {
      return;
    }

    const data = {
      formID: this.formID,
      shareLink: this.continueLink,
      email,
      sessionID: this.sessionID,
      token: this.token
    };

    Axios.post(`${this.apiURL}/sendSaveAndContinueEmail`, data)
      .then(resp => {
        if (resp.data.responseCode !== 200) {
          console.error('ERROR HAPPENED on SCL Mail (NOT 200)', resp);
        }
      })
      .catch(error => {
        error.json().then(err => {
          console.error('ERROR HAPPENED on SCL Mail (ERROR)', err);
        });
      });
  }

  setHiddenEmailSentTo = email => {
    const jotformForm = document.forms[0];
    let input = document.querySelector('input[name="jfFormUserSCL_emailSentTo"]');
    const append = !input;
    input = input ? input : this.createInput({ type: 'hidden', name: 'jfFormUserSCL_emailSentTo', value: email });
    input.value = email;
    if (append) {
      jotformForm.appendChild(input);
    }
  };

  getEmailSentTo = () => {
    const input = document.querySelector('input[name="jfFormUserSCL_emailSentTo"]');
    return input ? input.value : false;
  };

  hiddenSubmit = (forceSubmit, onSuccess, onError, loading) => {
    const jotformForm = document.forms[0];
    const currentValues = this.getInputNamesAndValues(jotformForm);
    const diff = this.getFormDataDiff(currentValues, this.lastAnswers, jotformForm);
    const loadingPendingSubm = window.JotForm.loadingPendingSubmission;

    const previouslyUploadedFiles = [];
    currentValues.forEach(each => {
      if (/uploadedBefore|_old/.test(each.name)) { // Piling both single and multiple prevly uploaded files to send always
        previouslyUploadedFiles.push(each);
      }
    });

    if ((diff.length > 0 || forceSubmit) && !loadingPendingSubm) {
      const merged = this.mergeDiffWithStaticData(diff);

      // Keep uploadedBefore files in the loop:
      // Because of 1774958 we're producing false diffs. Thus it causes extra saves.
      // In some cases uploadedBefore files are overridden as never set throughout saves and continues.
      // So we need to keep sending uploadedBefore files. Fixes 2079296
      merged.push(...previouslyUploadedFiles);

      if (!merged.length) {
        return;
      }
      const dummyForm = jotformForm.cloneNode(false);
      document.body.appendChild(dummyForm);
      for (let i = 0; i < merged.length; i++) {
        const p = merged[i];

        // need to skip file values. They will be added separately.
        if (typeof p.value === 'object') { // Most likely a file value..
          const fileInput = jotformForm.querySelector(`[name="${p.name}"][type="file"]`);
          if (fileInput) continue;
        }

        const input = this.createInput({ type: 'hidden', name: p.name, value: p.value });
        dummyForm.appendChild(input);
      }
      let singleFileUpload = false;
      // Appending single file inputs with their files
      Array.from(jotformForm.querySelectorAll('[type="file"]:not([multiple])'))
        .forEach(singleFileInput => {
          const fileInputClone = singleFileInput.cloneNode(true);
          fileInputClone.style.display = 'none';
          dummyForm.appendChild(fileInputClone);
          if (fileInputClone.files.length) {
            singleFileUpload = true;
          }
        });

      this.changeState(this.states.saving);
      window.JotForm.hiddenSubmit(dummyForm, {
        async: true,
        onSuccessCb: response => {
          // TODO: add interval on legacy forms
          //   if (!this.saveInterval) {
          //     this.saveInterval = setInterval(() => {
          //       this.hiddenSubmit();
          //     }, 5000);
          //   }
          // }
          // set the submissionId if it is not exist
          if (!window.JotForm.submissionID && response.responseText) {
            const resJSON = JSON.parse(response.responseText);
            if (resJSON.submissionId) {
              window.JotForm.setSubmissionID(resJSON.submissionId);
              const sidInput = this.createInput({
                type: 'hidden',
                name: 'submission_id',
                id: 'submission_id',
                value: resJSON.submissionId
              });
              jotformForm.appendChild(sidInput);
            }
          }

          // also set session id
          if (!jotformForm.querySelector('input#session')) {
            jotformForm.appendChild(this.createInput({
              type: 'hidden',
              name: 'session_id',
              id: 'session',
              value: this.sessionID
            }));
          }

          // if file uploaded then run makeUploadCheck to update related fields
          const regexTempFile = /temp_upload\[.*?]\[.*?]$/g; // file upload input name format
          const shouldUploadCheck = diff.some(d => regexTempFile.test(d.name));
          if (shouldUploadCheck || singleFileUpload) {
            window.JotForm.makeUploadChecks();
          }
          this.lastAnswers = currentValues;

          this.changeState(this.states.saved);

          if (typeof onSuccess === 'function') {
            onSuccess(response);
          }
          this.lastSubmissionSucceed = true;
        },
        onFailureCb: () => {
          this.changeState(this.states.error);
          if (typeof onError === 'function') {
            onError();
          }
          this.lastSubmissionSucceed = false;
        },
        onCompleteCb: () => {
          document.body.removeChild(dummyForm);
          this.removeSaveIndicator();
        },
        isClone: true
      });
    } else if (loading && diff.length === 0 && !loadingPendingSubm) {
      if (typeof this.lastSubmissionSucceed !== 'undefined') {
        if (this.lastSubmissionSucceed) {
          this.changeState(this.states.saved);
          if (typeof onSuccess === 'function') {
            onSuccess();
          }
        } else {
          this.changeState(this.states.error);
          if (typeof onError === 'function') {
            onError();
          }
        }
      } else { // There exist no submissions before
        this.changeState(this.states.saved);
        if (typeof onSuccess === 'function') {
          onSuccess();
        }
      }
    }
  };
}
