import React from 'react';
import PropTypes from 'prop-types';
import { t } from '@jotforminc/translation';
import { LoginFlow } from '@jotforminc/login-flow';
import { Texts } from '@jotforminc/constants';

import WithoutSignup from './WithoutSignup';
import SaclEpilogue from './SaclEpilogue';
import SaclSuccess from './SaclSuccess';
import SaclError from './SaclError';

const saclScreens = {
  withoutSignup: WithoutSignup,
  epilogue: SaclEpilogue,
  saclError: SaclError,
  saclSuccess: SaclSuccess
};

export default class SACLNavigation extends React.Component {
  handleUserLogin = () => {
    this.claimSubmission();
  };

  getInitialScreen = () => {
    const { user, manager } = this.props;
    const isSCLInitiated = manager.inited;
    this.isLoggedIn = !!user;

    let initialScreen;
    if ((isSCLInitiated || manager.getEmailSentTo())) {
      initialScreen = this.isLoggedIn ? 'epilogue' : 'withoutSignup';
    } else if (this.isLoggedIn) {
      initialScreen = 'epilogue';
    }
    return initialScreen;
  };

  claimSubmission = () => {
    if (window.JotForm.submissionID) {
      const formID = document.forms[0].id;
      const { apiURL } = this.props;
      fetch(`${apiURL}/form/${formID}/pendingsubmission/${window.JotForm.submissionID}/claim`, { method: 'POST' });
    }
  };

  render() {
    const {
      manager,
      apiURL,
      navigationWrapper,
      user,
      onUserLogout,
      skippable,
      enforceHIPAARuleSet,
      onModalClose
    } = this.props;

    return (
      <LoginFlow
        user={user}
        initialScreen={this.getInitialScreen()}
        manager={manager}
        onUserLogin={this.handleUserLogin}
        onUserLogout={onUserLogout}
        apiURL={apiURL}
        loginButtonText={t(Texts.LOGIN_SAVE)}
        signupButtonText={t(Texts.SAVE_SUBMISSION)}
        navigationWrapper={navigationWrapper}
        additionalScreens={saclScreens}
        skippable={skippable}
        enforceHIPAARuleSet={enforceHIPAARuleSet}
        greetingMessage={t(Texts.SAVE_PROGRESS)}
        onClose={onModalClose}
        buttonNames={{
          google: 'sacl-google-signup',
          facebook: 'sacl-facebook-signup',
          apple: 'sacl-apple-signup',
          emailLogin: 'sacl-standart-login-button',
          emailSignup: 'sacl-header-createmyaccount'
        }}
      />
    );
  }
}

SACLNavigation.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired
  }).isRequired,
  apiURL: PropTypes.string.isRequired,
  manager: PropTypes.shape({
    notSignedUpUserEmail: PropTypes.string,
    inited: PropTypes.bool.isRequired,
    initiate: PropTypes.func.isRequired,
    sendEmail: PropTypes.func.isRequired,
    getEmailSentTo: PropTypes.func.isRequired,
    userLoggedIn: PropTypes.bool
  }).isRequired,
  onUserLogout: PropTypes.func,
  navigationWrapper: PropTypes.instanceOf('<div />').isRequired,
  skippable: PropTypes.bool,
  enforceHIPAARuleSet: PropTypes.bool,
  onModalClose: PropTypes.func
};

SACLNavigation.defaultProps = {
  skippable: true,
  enforceHIPAARuleSet: false,
  onUserLogout: () => undefined,
  onModalClose: f => f
};
