/* eslint-disable max-statements */
import React, { useEffect, useState } from 'react';
import { TranslateProvider } from '@jotforminc/translation';
import {
  isEmail, emailAnswerFinder
} from '@jotforminc/utils';
import PropTypes from 'prop-types';
import { usePrevious } from '@jotforminc/hooks';
import '../styles/style.scss';
import { getAdapter } from '../adapters';
import {
  create, update, sendMail, updateOwner
} from '../api';
import {
  getCurrentFormID,
  getCurrentForm,
  getCurrentPage,
  getFolderName,
  setFolderName,
  logToSentry,
  getAllUrlParams,
  isPortal,
  generateSessionLink,
  appendDraftIDToForm,
  appendCurrentPageToForm,
  getCurrentBackStack,
  removeFileChangeInputs,
  getFieldType,
  getWidgetName,
  getFieldQuestionID,
  getHiddenInputs,
  getFileUploadChanges,
  objectHasAnyKey,
  sendSaveEventToAllWidgets,
  getFileServer,
  setUploadServerUrl,
  getUploadServerUrl
} from '../utils';
import {
  SC, DOM, excludedWidgets, excludedFields, Texts
} from '../constants';
import SaveModal from '../components/SaveModal.jsx';
import { DraftModal } from '../components';

const SaveAndContinue = props => {
  const {
    logout, user: userInfo, enforceHIPAARuleSet, continueKey: draftID, isHipaa, saclId, modal
  } = props;

  const { IDLE, SAVE, DRAFT } = SC.STATES;

  const [continueKey, setContinueKey] = useState(draftID || null);
  const [user, setUser] = useState(userInfo);
  const [state, setState] = useState(modal);
  const [draftKey, setDraftKey] = useState(null);
  const [isOwnerUpdateNeeded, setIsOwnerUpdateNeeded] = useState(false);
  const [oldParams, setOldParams] = useState({});
  const [serializedDataState, setSerializedDataState] = useState(null);
  const [isSomeData, setIsSomeData] = useState(false);

  const prevSerializedData = usePrevious(serializedDataState);

  const generateLink = (continueDraft = false) => {
    let continueLink = generateSessionLink({ continueKey: continueDraft ? draftKey : continueKey, formID: getCurrentFormID(), param: SC.PARAMETER });
    if (isPortal()) {
      const _params = { ...getAllUrlParams(), ...oldParams };
      const searchParams = new URLSearchParams(_params);
      searchParams.set('saclEmbed', '1');
      searchParams.delete('draft');
      if (searchParams.size > 0) {
        continueLink += `&${searchParams.toString()}`;
      }
    }
    return continueLink;
  };
  const sendEmail = async mail => {
    const email = mail || emailAnswerFinder();
    if (isSomeData) return;
    if (!isEmail(email)) {
      return false;
    }
    try {
      const res = await sendMail({
        formID: getCurrentFormID(),
        shareLink: generateLink(),
        email
      });
      return res;
    } catch (error) {
      logToSentry('Send Email Error', { error });
      return false;
    }
  };
  const serialize = () => {
    const form = getCurrentForm();
    if (form) {
      const fields = Array.from(document.querySelectorAll(DOM.FORM_FIELD_SELECTOR));
      const formData = new FormData(form);

      // for prefill
      const query = 'li.form-line input:disabled,  li.form-line select:disabled, li.form-line textarea:disabled';
      document.querySelectorAll(query).forEach(field => {
        const isRadioOrCheckbox = field.nodeName === 'INPUT' && (field.type === 'radio' || field.type === 'checkbox');
        if (isRadioOrCheckbox) {
          if (field.checked) {
            formData.append(field.name, field.value);
          }
        } else {
          formData.append(field.name, field.value);
        }
      });

      const formValues = Array.from(formData).map(([name, value]) => ({ name, value }));

      const excludeFieldsFilter = field => !excludedFields.includes(getFieldType(field));

      const excludeWidgetsFilter = field => !excludedWidgets.includes(getWidgetName(field));

      const removeEmptyValuesFilter = value => !!value;

      const formValueNameFilter = (value, params) => (value.name.split('_')[0] === `q${params.id}` || value.name.includes(`temp_upload[q${params.id}_`));

      const Filters = {
        ExcludeFields: 'EXCLUDE_FIELDS',
        ExcludeWidgets: 'EXCLUDE_WIDGETS',
        RemoveEmptyValues: 'REMOVE_EMPTY_VALUES',
        FormValueName: 'FORM_VALUE_NAME_FILTER'
      };

      const applyFilter = (array, filter, parameters = {}) => {
        try {
          switch (filter) {
            case Filters.ExcludeFields:
              return array.filter(excludeFieldsFilter);
            case Filters.ExcludeWidgets:
              return array.filter(excludeWidgetsFilter);
            case Filters.RemoveEmptyValues:
              return array.filter(removeEmptyValuesFilter);
            case Filters.FormValueName:
              return array.filter(value => formValueNameFilter(value, parameters));
            default:
              return array;
          }
        } catch (e) {
          throw new Error(`Filtering Error: ${e.message}`);
        }
      };

      const createQuestionPayload = field => {
        return {
          qid: getFieldQuestionID(field),
          type: getFieldType(field),
          value: ''
        };
      };

      const createSavePayload = () => {
        const payload = {
          data: {
            pageNo: getCurrentPage(),
            visitedPages: window.JotForm?.visitedPages,
            backStackHistory: getCurrentBackStack()
          },
          addValue(key, value) {
            payload.data[key] = value;
          },
          toJSON() {
            return JSON.stringify(payload.data);
          }
        };
        return payload;
      };

      const isEmptyField = fieldValues => {
        return fieldValues.every(v => v.value === '');
      };

      const EMPTY_PAYLOAD_VALUE = null;

      const Questions = {
        questions: [],
        getQuestions() {
          return Questions.questions;
        },
        setQuestions(value, settedFrom) {
          if (!Array.isArray(value)) { throw new Error(`Question Array cannot be set different from Array, Setted From: ${settedFrom}`); }
          if (value.length === 0 && settedFrom !== Filters.RemoveEmptyValues) { throw new Error(`Question Array cannot be set as Empty Array, Setted From: ${settedFrom}`); }
          Questions.questions = value;
        }
      };

      try {
        Questions.setQuestions(fields, 'Inital');
        Questions.setQuestions(applyFilter(Questions.getQuestions(), Filters.ExcludeFields), Filters.ExcludeFields);
        Questions.setQuestions(applyFilter(Questions.getQuestions(), Filters.ExcludeWidgets), Filters.ExcludeWidgets);

        const questions = Questions.getQuestions().map(question => {
          const payload = createQuestionPayload(question);
          const { qid, type } = payload;
          try {
            const adapter = getAdapter(type, qid);
            const values = applyFilter(formValues, Filters.FormValueName, { id: qid });
            if (isEmptyField(values)) return EMPTY_PAYLOAD_VALUE;
            payload.value = adapter.getValue(values);
            return payload;
          } catch (e) {
          // eslint-disable-next-line no-console
            console.log(`Question payload creation error, Question ID: ${qid}`);
            return EMPTY_PAYLOAD_VALUE;
          }
        });

        const hiddenQuestions = getHiddenInputs().map(input => ({
          qid: input.id,
          type: 'control_hidden',
          value: {
            data: input.value
          }
        }));

        Questions.setQuestions([...questions, ...hiddenQuestions], 'PAYLOAD_CREATION');
        Questions.setQuestions(applyFilter(Questions.getQuestions(), Filters.RemoveEmptyValues), Filters.RemoveEmptyValues);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e.message);
      }

      const payload = createSavePayload();

      payload.addValue('questions', Questions.getQuestions());
      payload.addValue('params', { ...oldParams, ...getAllUrlParams() });

      const folderName = getFolderName();
      if (folderName) {
        payload.addValue('folder', folderName);
      }

      const uploadServerUrl = getUploadServerUrl();
      if (uploadServerUrl) {
        payload.addValue('uploadServerUrl', uploadServerUrl);
      }

      const fileUploadChanges = getFileUploadChanges();
      if (objectHasAnyKey(fileUploadChanges)) {
        payload.addValue('fileUploadChanges', fileUploadChanges);
      }

      const fileServer = getFileServer();
      if (fileServer) {
        payload.addValue('fileServer', fileServer);
      }

      return payload.toJSON();
    }
  };
  const save = async () => {
    window.JotForm?.disableButtons();
    const serializedData = serialize();

    setSerializedDataState(serializedData);
    let result = false;
    if (continueKey || draftKey) {
      if (isOwnerUpdateNeeded && user) {
        result = await updateOwner(continueKey || draftKey);
        setIsOwnerUpdateNeeded(false);
      } else {
        // update
        result = await update(serializedData, continueKey || draftKey);
        if (result && !user) {
          setIsOwnerUpdateNeeded(true);
        }
      }
    } else {
      result = await create(serializedData);
      if (result && !user) {
        setIsOwnerUpdateNeeded(true);
      }
    }

    if (prevSerializedData !== null && JSON.stringify(prevSerializedData) === JSON.stringify(serializedData)) {
      setIsSomeData(true);
    } else {
      setIsSomeData(false);
    }

    window.JotForm?.enableButtons();
    if (result) {
      appendDraftIDToForm(result);
      setContinueKey(result);
      // Remove fileChange inputs
      removeFileChangeInputs();
      return Promise.resolve();
    }
    return Promise.reject();
  };
  const populate = async () => {
    window.JotForm?.disableButtons();
    try {
      const result = window.JotForm?.savedData.data;
      const {
        questions, folder, pageNo, params, visitedPages = {}, backStackHistory = [], uploadServerUrl
      } = result;
      window.JotForm.visitedPages = visitedPages;
      window.JotForm.adjustWorkflowFeatures(params);
      if (pageNo) {
        appendCurrentPageToForm(pageNo);
        window.JotForm.jumpToPage(pageNo, true);
      }
      if (folder) {
        setFolderName(folder);
      }
      if (uploadServerUrl) {
        setUploadServerUrl(uploadServerUrl);
      }
      if (backStackHistory && backStackHistory?.length > 0) {
        window.JotForm.backStack = backStackHistory.map(val => document.querySelector(val))?.filter(Boolean) || window.JotForm?.backStack;
      }
      const _result = [];
      questions.forEach(q => {
        try {
          const adapter = getAdapter(q.type, q.qid);
          if (adapter) {
            _result[q.qid] = adapter.setValue(q.value);
            if (typeof q.value.data !== 'string' && q.type !== 'control_fileupload') {
              _result[q.qid].items = q.value.data;
            }
          }
        } catch (error) {
          logToSentry(`Populating Error, Field Type: ${q.type}`, { error });
        }
      });
      window.JotForm.editMode({ result: _result }, true, false, 0, undefined, error => {
        logToSentry('Edit Mode Error', { error });
      });
      setOldParams(params);
    } catch (error) {
      logToSentry('Populate Error', { error });
    } finally {
      window.JotForm?.enableButtons();
    }
  };

  const handleClose = () => {
    setState(IDLE);
  };

  const onUserLogin = userData => {
    setUser(userData);
  };

  const onContinueDraft = () => {
    if (isPortal()) {
      window.JFAppsManager.continueWithDraftOnApp(getCurrentFormID(), null, null, draftKey);
      return;
    }
    // eslint-disable-next-line @jotforminc/no-location-href-assignment
    window.location.href = generateLink(true);
  };

  const onDiscard = () => {
    setState(IDLE);
  };

  useEffect(() => {
    const manuelsave = () => {
      sendSaveEventToAllWidgets();
      setState(SAVE);
    };

    const autosave = async () => {
      sendSaveEventToAllWidgets();
      const serializedData = serialize();
      const result = await update(serializedData, continueKey || draftKey);
      if (result) {
        removeFileChangeInputs();
      }
    };

    document.querySelectorAll(DOM.SAVE_BUTTON_SELECTOR).forEach(target => {
      target.addEventListener('click', manuelsave);
    });

    if (continueKey) {
      document.querySelectorAll(DOM.NEXT_BUTTON_SELECTOR).forEach(target => {
        target.addEventListener('click', autosave);
      });
    }

    return () => {
      document.querySelectorAll(DOM.NEXT_BUTTON_SELECTOR).forEach(target => {
        target.removeEventListener('click', autosave);
      });

      document.querySelectorAll(DOM.SAVE_BUTTON_SELECTOR).forEach(target => {
        target.removeEventListener('click', manuelsave);
      });
    };
  }, [oldParams]);

  useEffect(() => {
    const isDraft = window.location.pathname.match(/^\/draft\//);
    if (window.history.state?.submitted && isDraft) {
      window.history.replaceState(null, '');
      window.location.reload();
    }
    if (isPortal()) {
      const url = window.parent?.document.location.href;
      if (url?.includes('/app/build/')) return;
    }
    if (!continueKey && user && state === IDLE) {
      if (saclId) {
        appendDraftIDToForm(saclId);
        setDraftKey(saclId);
        setState(DRAFT);
      }
    }
    const form = getCurrentForm();
    if (form && isDraft) {
      form.addEventListener('submit', () => {
        window.history.replaceState({ submitted: true }, '');
      });
    }
  }, []);

  useEffect(() => {
    if (continueKey && state === IDLE) {
      appendDraftIDToForm(continueKey);
      populate();
    }
  }, [continueKey]);

  if (state === IDLE) {
    return null;
  }

  if (state === DRAFT) {
    return <TranslateProvider translationTexts={Texts}><DraftModal onDiscard={onDiscard} onContinue={onContinueDraft} /></TranslateProvider>;
  }

  let skipLogin;

  if (window.JFForm?.requireLogin === undefined) {
    skipLogin = continueKey;
  } else {
    skipLogin = !window.JFForm.requireLogin;
  }

  return (
    <SaveModal
      isHipaa={isHipaa}
      user={user}
      skipLogin={skipLogin}
      enforceHIPAARuleSet={enforceHIPAARuleSet}
      onLogin={onUserLogin}
      onClose={handleClose}
      generateLink={generateLink}
      sendEmail={sendEmail}
      save={save}
      logout={logout}
    />
  );
};

export default SaveAndContinue;

SaveAndContinue.propTypes = {
  user: PropTypes.any.isRequired,
  logout: PropTypes.func.isRequired,
  enforceHIPAARuleSet: PropTypes.bool.isRequired,
  continueKey: PropTypes.string.isRequired,
  isHipaa: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool
  ]).isRequired,
  saclId: PropTypes.string.isRequired,
  modal: PropTypes.string
};

SaveAndContinue.defaultProps = {
  modal: 'IDLE'
};
